import React, {useContext} from "react"
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider"
import {Wrapper, BlockContent} from "./CallToActionBlock.styled"
import 'twin.macro'

//Assets
import bottomline from "../../../images/hero-bottomline.png"

const CallToActionBlock = ({assets, posts})=> {  
    //console.log(assets);
    const dispatch = useContext(GlobalDispatchContext)
    
    const openModal = (item)=> {
        dispatch({
            type: 'MODAL_CONTENT',
            payload: {
                name: item.title,
                jobTitle: item.ACF_leadership_post_details.jobTitle,
                bio: item.content,
                banner: item.ACF_leadership_post_details.pictures.leaderboardBanner 
                    ? item.ACF_leadership_post_details.pictures.leaderboardBanner.localFile.publicURL 
                    : null
            }
        })
    }
    
    return(
        <Wrapper>

            <BlockContent>

               <h2>{assets.title}</h2> 

               <div className="cta-wrapper">

                    <div className="sarah">
                        <img src={posts[0].ACF_leadership_post_details.pictures.portrait.localFile.publicURL} /><br />
                        <button 
                            onClick={ () => openModal(posts[0]) }
                            onKeyDown={ () => openModal(posts[0]) }
                        >
                            {`Meet ${posts[0].title.split(" ")[0]}`}
                        </button>
                    </div>

                    <p className="cofounders-center-copy">{assets.mainCopy}</p>

                    <div className="john">
                        <img src={posts[1].ACF_leadership_post_details.pictures.portrait.localFile.publicURL} /><br />
                        <button 
                            onClick={ () => openModal(posts[1]) }
                            onKeyDown={ () => openModal(posts[1]) }
                        >
                            {`Meet ${posts[1].title.split(" ")[0]}`}
                        </button>
                    </div>

               </div>

            </BlockContent>

            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default CallToActionBlock