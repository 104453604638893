import React from "react"
import { Wrapper, DesktopImage, MobileImage, BlockContent, BlockContentMobile, MainCopyMobile} from "./TopHero.styled"
import TypeWriteLine from "../../TypeWriteLine/TypeWriteLine"
import 'twin.macro'

//Assets
import bottomline from "../../../images/hero-bottomline.png"
import chooseLogo from "../../../images/choose-logo-white.svg"


const TopHero = ({assets})=> {
    
    return(
        <Wrapper>
            <DesktopImage image={assets.desktopImage.localFile.childImageSharp.gatsbyImageData} alt=""/>

            <BlockContent>
                <div className="boxed">
                    <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                    <TypeWriteLine copy={assets.title}/>
                    <p>{assets.mainCopy}</p>
                </div>
            </BlockContent>

            <div tw="relative">
                <MobileImage image={assets.mobileImage.localFile.childImageSharp.gatsbyImageData} alt=""/>

                <BlockContentMobile>
                    <div className="boxed">
                        <img className="chooseLogo" src={chooseLogo} alt="Choose"/>
                        <TypeWriteLine copy={assets.title}/>
                    </div>
                </BlockContentMobile>
            </div>

            <MainCopyMobile>{assets.mainCopy}</MainCopyMobile>

            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default TopHero