import * as React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import TopHero from "../components/Leadership/TopHero/TopHero"
import CallToActionBlock from "../components/Leadership/CallToActionBlock/CallToActionBlock"
import LeadershipPosts from "../components/Leadership/LeadershipPosts/LeadershipPosts"
import {GlobalStateContext, GlobalDispatchContext } from "../../src/context/GlobalContextProvider"

import { useLeadershipQuery } from "../hooks/useLeadershipQueries"
import BioModal from "../components/Leadership/BioModal/BioModal"



const Leadership = ({ children }) => {

  const data = useLeadershipQuery();

  const dispatch = React.useContext(GlobalDispatchContext)
  const state = React.useContext(GlobalStateContext)

  const leadershipPostsArray = data.allWpPost.nodes.filter((item) => 
    item.categories.nodes.every(sub => sub.name !== "co-founders" )
  )

  const cofoundersPostsArray = data.allWpPost.nodes.filter((item) => 
    item.categories.nodes.some(sub => sub.name === "co-founders" )
  )

  React.useEffect(() => {
    // cleanup function will be called when component is removed
    return () => {
      dispatch({
        type: 'MODAL_CONTENT',
        payload: null
      })
      document.body.classList.remove('modal-open')
    }
  },[dispatch]); 

  return(
    <Layout>
      <Seo title="Leadership" />

      <TopHero assets={data.wpPage.ACF_LeadershipPage.mainHero} />

      <CallToActionBlock assets={data.wpPage.ACF_LeadershipPage.callToActionBlock} posts={cofoundersPostsArray} />

      <LeadershipPosts info={data.wpPage.ACF_LeadershipPage.leadershipBlock} posts={leadershipPostsArray}/>

      {state !== undefined && state.modalContent !== null
        ? (<BioModal content={state.modalContent}/> )
        : (null)
      }

    </Layout>
  )

}

export default Leadership
