import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`relative bg-lightorange`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const BlockContent = styled.div`
  ${tw`flex flex-col items-center justify-center px-4 sm:px-8 xl:px-0 box-border text-white max-w-screen-xl mx-auto`}

  h2{
    ${tw`text-left sm:text-center`}
  }

  p{
      ${tw`text-left sm:text-center`}
      max-width: 570px;
  }

  .cta-wrapper{
      ${tw`grid grid-cols-1 md:grid-cols-3 gap-5 mb-16`}
      max-width: 1120px;
      
      > div {
        ${tw`text-center`}
      }

      .cofounders-center-copy{
        ${tw`mt-0 order-1	sm:order-none`}
      }
      .sarah{
        ${tw`order-2	sm:order-none`}
      }
      .john{
        ${tw`order-3	sm:order-none`}
      }

      img {
        ${tw`mb-4`}
        max-width: 100%;
      }

      button{
          ${tw`
              bg-lightorange text-white border-white   hover:bg-white hover:text-lightorange  transition-all cursor-pointer
              text-center border-0 border-8 border-solid  px-8 py-1 rounded-full font-bold no-underline  uppercase my-4 sm:my-0
          `}
      }

    }
  }
  
`