import React, {useContext} from "react"
import {Wrapper, BlockContent} from "./LeadershipPosts.styled"
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider"
import 'twin.macro'

//Assets
import bottomline from "../../../images/hero-bottomline.png"

const LeadershipPosts = ({info, posts})=> { 
    
    const dispatch = useContext(GlobalDispatchContext)

    const openModal = (item)=> {
        dispatch({
            type: 'MODAL_CONTENT',
            payload: {
                name: item.title,
                jobTitle: item.ACF_leadership_post_details.jobTitle,
                bio: item.content,
                banner: item.ACF_leadership_post_details.pictures.leaderboardBanner 
                    ? item.ACF_leadership_post_details.pictures.leaderboardBanner.localFile.publicURL 
                    : null
            }
        })
    }

    return(
        <Wrapper>
            <BlockContent>
               <h2>{info.title}</h2> 
               <p>{info.mainCopy}</p>

                <div className="posts-grid">{posts.map((item, index)=>(
                    <div className="card" key={index} tabIndex="0" role="button" onClick={ () => openModal(item) } onKeyDown={ () => openModal(item) }>
                        <img className="portrait-photo" src={item.ACF_leadership_post_details.pictures.portrait.localFile.publicURL} alt=""/>
                        <div className="data-ribbon">
                            <h3>{item.title}</h3>
                            <p>{item.ACF_leadership_post_details.jobTitle}</p>
                        </div>
                    </div>
                ))}</div>
            </BlockContent>

            <img className="bottomline" src={bottomline} alt=""/>
        </Wrapper>
    )
}

export default LeadershipPosts