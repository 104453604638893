import tw, { styled } from "twin.macro"
import { GatsbyImage } from "gatsby-plugin-image"

export const Wrapper = styled.div`
    ${tw`relative`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const DesktopImage = styled(GatsbyImage)`
    ${tw`hidden sm:block`}
    min-height: 300px;
`

export const MobileImage = styled(GatsbyImage)`
    ${tw`block sm:hidden`}
`

export const BlockContent = styled.div`
  ${tw`hidden sm:flex flex-col items-start justify-start w-full h-full inset-0 px-4 sm:px-8 xl:px-0 box-border absolute text-white max-w-screen-xl mx-auto`}
  
  .boxed{
      ${tw`bg-redglass w-3/4 lg:w-2/3 xl:w-1/2 h-full px-6 pt-5 lg:pt-10`}

      .chooseLogo{
          ${tw`block`}
      }

  }
`

export const BlockContentMobile = styled.div`
  ${tw`flex sm:hidden flex-col items-center justify-center w-full h-full inset-0 absolute text-white max-w-screen-xl mx-auto`}
  
  .boxed{
      ${tw`bg-redglass flex flex-col items-center w-full py-4`}

      .chooseLogo{
        ${tw`block`}
        max-width: 11rem;
      }

      h2{
        ${tw`block`}
      }

  } 
`

export const MainCopyMobile = styled.div`
    ${tw`block sm:hidden text-white bg-darkgray px-4 pt-4 pb-6 box-border`}
`
