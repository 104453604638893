import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
    ${tw`relative bg-bonegray`}

    .bottomline{
        ${tw`w-full h-2 absolute bottom-0 left-0`}    
    }
`

export const BlockContent = styled.div`
  ${tw`flex flex-col items-center justify-center px-4 sm:px-8 xl:px-0 box-border text-darkgray max-w-screen-xl mx-auto`}

  h2{
    ${tw`text-left sm:text-center`}
    max-width: 55rem;
  }

  p{
      ${tw`text-left sm:text-center`}
      max-width: 570px;
  }  

  .posts-grid{
      ${tw`flex flex-row flex-wrap justify-center mb-10`}

      .card{
        ${tw`relative mx-auto sm:mx-10 my-10 cursor-pointer`}

        .portrait-photo{
            max-width: 321px;
            max-height: 321px;
            width: 100%;
        }

        .data-ribbon{
            ${tw`absolute w-full box-border bg-darkglass text-white px-3 py-1`}
            bottom: 10%;

            h3{
                ${tw`uppercase m-0 p-0`}
            }
            p{
                ${tw`text-base text-left m-0 p-0`}
            }
        }
      }
  }
`