import React, {useContext, useEffect, useRef} from "react";
import BottomLine from "../../BottomLine/BottomLine"
import {ModalWrapper} from "./BioModal.styled"
import {GlobalDispatchContext } from "../../../../src/context/GlobalContextProvider"
import gsap from 'gsap';

import closeButton from "../../../images/close-btn.svg"


const BioModal = ({content}) => {

    const dispatch = useContext(GlobalDispatchContext);
    const modalWrapper = useRef();
    const $ = gsap.utils.selector(modalWrapper);

    const closeModal = (e)=> {
        if(!e.target.classList.contains('close-trigger')) return;

        let tl = new gsap.timeline()
        tl.to($('.modal-box'), {opacity:0, duration:0.2})
        tl.to(modalWrapper.current, {opacity:0, duration:0.2, onComplete:()=>{
            dispatch({type: 'MODAL_CONTENT', payload: null})
            document.body.classList.remove('modal-open')
        }}, "+=0.0")
    }

    useEffect(() => {
        document.body.classList.add('modal-open')

        //Animation
        let tl = new gsap.timeline()
        tl.from(modalWrapper.current, {opacity:0, duration:0.2})
        tl.from($('.modal-box'), {opacity:0, duration:0.2})
        tl.from($('.banner-wrap'), {opacity:0}, "-=0.1")
        tl.from($('.text-wrap'), {opacity:0}, "-=0.2")

        // cleanup function will be called when component is removed
        return () => {
        }
  
    }, [$]);

    return(
        <ModalWrapper className="close-trigger" ref={modalWrapper} onClick={closeModal}>

            <button className="btn-close close-trigger" onClick={closeModal}>
                <img src={closeButton} alt=""/>
            </button>

            <div className="modal-box">
                {content.banner && (
                    <div className="banner-wrap">
                        <img className="banner" src={content.banner} alt=""/>
                        <BottomLine/>
                    </div>
                )}
                <div className="text-wrap">
                    <h3>{content.name}</h3>
                    <h4>{content.jobTitle}</h4>
                    <div className="scrollable" dangerouslySetInnerHTML={{ __html: content.bio }}></div>
                </div>
            </div>
        </ModalWrapper>
    )
}

export default BioModal;