import tw, { styled } from "twin.macro"

export const ModalWrapper = styled.div`
    ${tw`fixed bg-modalgray w-full flex justify-center items-center px-6 box-border`}
    height: 90vh;

    .btn-close{
        ${tw`absolute m-0 p-0 right-5 top-5 bg-transparent outline-none border-none cursor-pointer`}
        img{
            pointer-events: none;
        }
    }

    .modal-box{
        ${tw`bg-white rounded-lg overflow-hidden flex flex-col`}
        box-shadow: 0 6px 18px rgb(0 0 0 / 30%);
        max-width: 680px;
        max-height: 580px;
        height: 70vh;

        .banner-wrap{
            ${tw`relative`}
            font-size: 0;

            .banner{
                ${tw`w-full`}
            }
            .bottomline{
                height: 4px;
            }
        }

        .text-wrap{
            ${tw`px-8 overflow-auto flex flex-col`}

            h3{
                ${tw`text-4xl uppercase mb-0`}
            }
            h4{
                ${tw`text-2xl text-lightorange my-0`}
            }
            .scrollable{
                ${tw`h-full overflow-auto mb-8`}

                p{
                    ${tw`font-thin text-xl mt-4 mb-0`}
                }
            }
        }
    }
`