import { useStaticQuery, graphql } from "gatsby"

export const useLeadershipQuery = ()=> {

    return useStaticQuery(graphql`
    
        query Leadership {

            wpPage(title: {eq: "LEADERSHIP"}) {
                ACF_LeadershipPage {
                    
                    mainHero {
                        mainCopy
                        title
                        desktopImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                        mobileImage {
                            localFile {
                                childImageSharp {
                                    gatsbyImageData
                                }
                            }
                        }
                    }

                    callToActionBlock {
                        mainCopy
                        title
                        ctas {
                            textToDisplay
                            url
                            thumbnail {
                                localFile {
                                    publicURL
                                    childImageSharp {
                                        gatsbyImageData
                                    }
                                }
                            }
                        }
                    }

                    leadershipBlock {
                        mainCopy
                        title
                    }

                }
            }


            allWpPost(filter: {categories: {nodes: {elemMatch: {name: {eq: "Leadership"}}}}}) {
                nodes {
                    title
                    content
                    categories {
                        nodes {
                            name
                        }
                    }
                    ACF_leadership_post_details {
                        pictures {
                            leaderboardBanner {
                                localFile {
                                    publicURL
                                }
                            }
                            portrait {
                                localFile {
                                    publicURL
                                }
                            }
                        }
                        jobTitle
                    }
                }
            }

            
        }
        
    `)
}